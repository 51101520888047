import styled from 'styled-components';

import {
  getThemeColor,
  getThemeColorWithError,
  getThemePath,
  getThemeTransition,
} from '@utils/styled';

import * as FormControl from '@common/components/forms/FormControl/FormControl.styled';

export const Label = styled(FormControl.Label)`
  flex: 1 1 auto;
  width: 0;
  ${({ widthDefaultSpacing, theme }) =>
    widthDefaultSpacing
      ? theme.getFluidSpacing('margin-left', 'equal', 5)
      : null}
`;

export const LabelRow = styled(FormControl.LabelRow)``;

export const LabelText = styled(FormControl.LabelText)``;

export const OptionalText = styled(FormControl.OptionalText)``;

export const Input = styled.input`
  flex: 1 1 auto;
  width: auto;
  max-width: 100%;
  height: 5.1rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${getThemeColorWithError('bluegrey.80', 'hue.red')};
  border-radius: ${getThemePath(['borderRadiuses', 'normal.1'])}rem;
  padding: 0 2rem;
  background-color: ${getThemeColorWithError('grey.100', 'hue.errorYellow')};
  ${({ theme }) => theme.getFluidFont('paragraph2')};
  color: ${getThemeColor('bluegrey.80')};
  line-height: 5.1rem;
  transition: ${getThemeTransition('all')};
  caret-color: ${({ asPlaceholder }) =>
    asPlaceholder
      ? getThemeColor('bluegrey.80')
      : getThemeColorWithError('bluegrey.15', 'hue.red')};

  ${({ widthDefaultSpacing, theme }) =>
    widthDefaultSpacing
      ? theme.getFluidSpacing('margin-left', 'equal', 5)
      : null}
  ${/* sc-sel */ FormControl.Label} & {
    width: 0;
  }

  ${/* sc-sel */ Label} & {
    width: 100%;
  }

  &:disabled {
    border-color: ${getThemeColor('bluegrey.95')};
    background-color: ${getThemeColor('bluegrey.95')};
  }

  &:autofill {
    box-shadow: 0 0 0 max(1000vmax, 10000px) ${getThemeColor('hue.aliceBlue')}
      inset;
    border-color: ${getThemeColor('hue.malibu')} !important;
  }

  &:hover:not(:disabled) {
    border-color: ${getThemeColorWithError('bluegrey.60', 'hue.red')};
    transition: ${getThemeTransition('all')};
  }

  &:focus:not(:disabled) {
    outline: none;
    border-color: ${getThemeColorWithError('bluegrey.15', 'hue.red')};
    transition: ${getThemeTransition('all')};
  }

  &::placeholder {
    opacity: 1;
    color: ${getThemeColor('bluegrey.80')};
  }

  ${
    /* sc-block */ ({ type }) =>
      type === 'number' &&
      `
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      & {
        -moz-appearance: textfield;
      }
  `
  }
`;

export const InputWrapper = styled.span`
  position: relative;
  display: flex;
  width: 100%;
`;

export const InputPlaceholder = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  display: flex;
  width: 100%;
  padding-right: 2rem;
  color: ${getThemeColorWithError('bluegrey.15', 'hue.red')};
  font-size: 2rem;

  ${({ $isPhoneNumberField, $fixedPrefix }) =>
    !$isPhoneNumberField
      ? 'padding-left: 2.1rem;'
      : `padding-left: ${$fixedPrefix ? '11.4rem' : '13.1rem'};`}
  line-height: 5.1rem;
  transition: ${getThemeTransition('color')};
  pointer-events: none;

  & span {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }
`;
