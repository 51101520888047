export const COUNTRY_SWITZERLAND = 'CH';
export const COUNTRY_GERMANY = 'DE';
export const COUNTRY_LIECHTENSTEIN = 'LI';
export const COUNTRY_AUSTRIA = 'AT';
export const COUNTRY_FRANCE = 'FR';
export const COUNTRY_ITALY = 'IT';

export const COUNTRIES = [
  COUNTRY_SWITZERLAND,
  COUNTRY_GERMANY,
  COUNTRY_LIECHTENSTEIN,
  COUNTRY_AUSTRIA,
  COUNTRY_FRANCE,
  COUNTRY_ITALY,
];

export const getCountriesForPrefix = t => ({
  [COUNTRY_SWITZERLAND]: t('country.switzerland', 'Switzerland'),
  [COUNTRY_GERMANY]: t('country.germany', 'Germany'),
  [COUNTRY_LIECHTENSTEIN]: t('country.liechtenstein', 'Liechtenstein'),
  [COUNTRY_AUSTRIA]: t('country.austria', 'Austria'),
  [COUNTRY_FRANCE]: t('country.france', 'France'),
  [COUNTRY_ITALY]: t('country.italy', 'Italy'),
});

export function getCountriesOptions(t, seletedOptions = []) {
  const options = [
    {
      label: t('country.switzerland', 'Switzerland'),
      value: COUNTRY_SWITZERLAND,
    },
    {
      label: t('country.liechtenstein', 'Liechtenstein'),
      value: COUNTRY_LIECHTENSTEIN,
    },
    {
      label: t('country.germany', 'Germany'),
      value: COUNTRY_GERMANY,
    },
    {
      label: t('country.austria', 'Austria'),
      value: COUNTRY_AUSTRIA,
    },
    {
      label: t('country.france', 'France'),
      value: COUNTRY_FRANCE,
    },
    {
      label: t('country.italy', 'Italy'),
      value: COUNTRY_ITALY,
    },
  ];

  return seletedOptions.length > 0
    ? options.filter(option => seletedOptions.includes(option.value))
    : options;
}

export const isCountryAutocomplete = country =>
  country === COUNTRY_SWITZERLAND || country === COUNTRY_LIECHTENSTEIN;

export const COUNTRY_PHONE_PREFIX_AUSTRIA = '+43';
export const COUNTRY_PHONE_PREFIX_FRANCE = '+33';
export const COUNTRY_PHONE_PREFIX_GERMANY = '+49';
export const COUNTRY_PHONE_PREFIX_ITALY = '+39';
export const COUNTRY_PHONE_PREFIX_LIECHTENSTEIN = '+423';
export const COUNTRY_PHONE_PREFIX_SWITZERLAND = '+41';

export const PHONE_PREFIX_TO_MASK_AND_PLACEHOLDER_MAP = {
  [COUNTRY_PHONE_PREFIX_SWITZERLAND]: {
    mask: '00 000 00 00',
    placeholder: '12 345 67 89',
  },
  [COUNTRY_PHONE_PREFIX_LIECHTENSTEIN]: {
    mask: '000 00 00',
    placeholder: '123 45 67',
  },
  [COUNTRY_PHONE_PREFIX_GERMANY]: {
    mask: '00 00000 00',
    placeholder: '12 34567 89',
  },
  [COUNTRY_PHONE_PREFIX_AUSTRIA]: {
    mask: '0 00000 0',
    placeholder: '1 23456 7',
  },
  [COUNTRY_PHONE_PREFIX_FRANCE]: {
    mask: '0 00 00 00 00',
    placeholder: '1 23 45 67 89',
  },
  [COUNTRY_PHONE_PREFIX_ITALY]: {
    mask: '000 0000000',
    placeholder: '123 4567890',
  },
};

export const PHONE_PREFIX_TO_COUNTRY_MAP = {
  [COUNTRY_PHONE_PREFIX_AUSTRIA]: COUNTRY_AUSTRIA,
  [COUNTRY_PHONE_PREFIX_FRANCE]: COUNTRY_FRANCE,
  [COUNTRY_PHONE_PREFIX_GERMANY]: COUNTRY_GERMANY,
  [COUNTRY_PHONE_PREFIX_ITALY]: COUNTRY_ITALY,
  [COUNTRY_PHONE_PREFIX_LIECHTENSTEIN]: COUNTRY_LIECHTENSTEIN,
  [COUNTRY_PHONE_PREFIX_SWITZERLAND]: COUNTRY_SWITZERLAND,
};

export const COUNTRY_PHONE_PREFIXES_MAP = {
  [COUNTRY_AUSTRIA]: COUNTRY_PHONE_PREFIX_AUSTRIA,
  [COUNTRY_FRANCE]: COUNTRY_PHONE_PREFIX_FRANCE,
  [COUNTRY_GERMANY]: COUNTRY_PHONE_PREFIX_GERMANY,
  [COUNTRY_ITALY]: COUNTRY_PHONE_PREFIX_ITALY,
  [COUNTRY_LIECHTENSTEIN]: COUNTRY_PHONE_PREFIX_LIECHTENSTEIN,
  [COUNTRY_SWITZERLAND]: COUNTRY_PHONE_PREFIX_SWITZERLAND,
};

export const COUNTRY_PHONE_PREFIXES = [
  COUNTRY_PHONE_PREFIX_LIECHTENSTEIN,
  COUNTRY_PHONE_PREFIX_SWITZERLAND,
  COUNTRY_PHONE_PREFIX_AUSTRIA,
  COUNTRY_PHONE_PREFIX_FRANCE,
  COUNTRY_PHONE_PREFIX_GERMANY,
  COUNTRY_PHONE_PREFIX_ITALY,
].sort((a, b) => b.length - a.length);

export const COUNTRY_CIRCLE_FLAGS = {
  [COUNTRY_AUSTRIA]: 'CircleFlagAustria',
  [COUNTRY_FRANCE]: 'CircleFlagFrance',
  [COUNTRY_GERMANY]: 'CircleFlagGermany',
  [COUNTRY_ITALY]: 'CircleFlagItaly',
  [COUNTRY_LIECHTENSTEIN]: 'CircleFlagLiechtenstein',
  [COUNTRY_SWITZERLAND]: 'CircleFlagSwitzerland',
};

export const PHONES_OPTIONS_ORDER = [
  COUNTRY_SWITZERLAND,
  COUNTRY_LIECHTENSTEIN,
  COUNTRY_GERMANY,
  COUNTRY_AUSTRIA,
  COUNTRY_FRANCE,
  COUNTRY_ITALY,
];
