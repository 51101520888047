import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';

import { TextareaElement } from './Textarea.styled';

const Textarea = ({
  autoresize,
  noUserResize,
  hasError,
  className,
  ...props
}) => (
  <TextareaElement
    as={autoresize ? TextareaAutosize : 'textarea'}
    {...props}
    className={className}
    $noUserResize={noUserResize}
    $hasError={hasError}
  />
);

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  autoresize: PropTypes.bool,
  noUserResize: PropTypes.bool,
  hasError: PropTypes.bool,
};

Textarea.defaultProps = {
  value: undefined,
  placeholder: undefined,
  maxLength: undefined,
  className: undefined,
  autoresize: true,
  noUserResize: true,
  hasError: false,
};

export default Textarea;
