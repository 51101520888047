import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';

import { Eye, EyeCrossed } from '@common/components/Icons';

import {
  Input,
  InputWrapper,
  Label,
  LabelRow,
  LabelText,
  OptionalText,
  PasswordToggle,
} from './TextInput.styled';

const NUMBERS_KEYS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const SHORTCUTS_KEYS = ['a', 'c', 'v', 'x', 'z'];
const SPECIAL_KEYS = [
  'Delete',
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'Home',
  'End',
  'Tab',
];

const ARROW_UP_DOWN_KEYS = ['ArrowUp', 'ArrowDown'];

export const handleKeyDown = (e, numbersOnly, disableArrowUpDown) => {
  if (numbersOnly) {
    const { key } = e;

    if (
      SPECIAL_KEYS.includes(key) ||
      (SHORTCUTS_KEYS.includes(key) &&
        (e.ctrlKey === true || e.metaKey === true))
    ) {
      return;
    }

    if (!disableArrowUpDown && ARROW_UP_DOWN_KEYS.includes(key)) {
      return;
    }

    if (!NUMBERS_KEYS.includes(key)) {
      e.preventDefault();
    }
  }
};

export const TextInput = ({
  className,
  label,
  hasError,
  optional,
  optionalText,
  value,
  onChange,
  numbersOnly,
  widthDefaultSpacing,
  type,
  id,
  disableArrowUpDown,
  ...props
}) => {
  const themeContext = useContext(ThemeContext);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const isPasswordField = type === 'password';

  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible(visible => !visible);
  }, []);

  const getInputType = useCallback(() => {
    if (type === 'password') {
      return isPasswordVisible ? 'text' : 'password';
    }

    return type;
  }, [isPasswordVisible, type]);

  const inputType = getInputType();

  if (label) {
    const renderLabel = () => (
      <LabelText hasError={hasError}>{label}</LabelText>
    );
    const renderOptionalHint = () => (
      <OptionalText hasError={hasError}>{optionalText}</OptionalText>
    );

    return (
      <Label
        className={className}
        widthDefaultSpacing={widthDefaultSpacing}
        htmlFor={id}
      >
        <LabelRow>
          {typeof label === 'string' ? renderLabel() : label}
          {optional && renderOptionalHint()}
        </LabelRow>
        <InputWrapper>
          <Input
            hasError={hasError}
            onKeyDown={handleKeyDown}
            onChange={onChange}
            value={value}
            type={inputType}
            id={id}
            $isPasswordField={isPasswordField}
            {...props}
          />
          {isPasswordField && (
            <PasswordToggle
              onClick={togglePasswordVisibility}
              type="button"
              data-testid="password-visibility-toggle"
              isPasswordVisible={isPasswordVisible}
            >
              {isPasswordVisible ? (
                <Eye fill={themeContext.colors.hue.blue} />
              ) : (
                <EyeCrossed fill={themeContext.colors.bluegrey[70]} />
              )}
            </PasswordToggle>
          )}
        </InputWrapper>
      </Label>
    );
  }

  return (
    <InputWrapper className={className}>
      <Input
        hasError={hasError}
        widthDefaultSpacing={widthDefaultSpacing}
        onKeyDown={e => handleKeyDown(e, numbersOnly, disableArrowUpDown)}
        onChange={onChange}
        value={value}
        type={inputType}
        id={id}
        $isPasswordField={isPasswordField}
        {...props}
      />
      {type === 'password' && (
        <PasswordToggle onClick={togglePasswordVisibility} type="button">
          {isPasswordVisible ? (
            <Eye fill={themeContext.colors.hue.blue} />
          ) : (
            <EyeCrossed fill={themeContext.colors.bluegrey[70]} />
          )}
        </PasswordToggle>
      )}
    </InputWrapper>
  );
};

TextInput.defaultProps = {
  className: undefined,
  disableArrowUpDown: false,
  hasError: false,
  id: undefined,
  label: undefined,
  numbersOnly: false,
  onChange: undefined,
  optional: false,
  optionalText: 'optional',
  placeholder: null,
  type: 'text',
  value: undefined,
  widthDefaultSpacing: false,
};

TextInput.propTypes = {
  className: PropTypes.string,
  disableArrowUpDown: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  numbersOnly: PropTypes.bool,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  optionalText: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  widthDefaultSpacing: PropTypes.bool,
};
