import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { selectAddressApiToken } from '@selectors/addressApi';

import { getErrorMessage } from '@utils/validation';
import { getZipCodeMaxLength, getZipCodePlaceholder } from '@utils/forms';
import { checkIsStreetNumber } from '@utils/profileForms';

import {
  FormControl,
  PostalCodeAndCityInputsWrapper,
  Select,
  StreetInputsWrapper,
  TextInput,
} from '@common/components/forms';
import {
  getCountriesOptions,
  isCountryAutocomplete,
} from '@common/constants/countries';
import { formikShape } from '@common/types/formik';

import { FormColumn, FormRow } from './AsyncAddressForm.styled';

const { ADDRESS_API_URL } = getConfig().publicRuntimeConfig;

const getOptionsResult = (response, key) => {
  const resultList =
    response.data?.QueryAutoComplete4Result?.AutoCompleteResult?.map(
      keyData => keyData[key],
    ) || [];

  return [...new Set(resultList)].map(value => ({
    label: value,
    value,
  }));
};

const getAsyncOptions = async (formValues, token, formNamesMap) => {
  const response = await axios.post(
    `${ADDRESS_API_URL}/autocomplete4`,
    {
      request: {
        ONRP: 0,
        ZipCode: formValues[formNamesMap.zipCode] || '',
        ZipAddition: '',
        TownName: formValues[formNamesMap.city] || '',
        STRID: 0,
        StreetName: formValues[formNamesMap.street] || '',
        HouseKey: 0,
        HouseNo: formValues[formNamesMap.streetNr] || '',
        HouseNoAddition: '',
      },
      zipOrderMode: 0,
      zipFilterMode: 0,
    },
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return {
    zipCodes: getOptionsResult(response, 'ZipCode'),
    cities: getOptionsResult(response, 'TownName'),
    street: getOptionsResult(response, 'StreetName'),
    streetNumbers: getOptionsResult(response, 'HouseNo').sort(
      (a, b) => a.value - b.value,
    ),
  };
};

const getDefaultValueFromDefaultOptions = (key, defaultOptions, value) => {
  if (defaultOptions[key]) {
    return defaultOptions[key].find(
      option => option.value.toLowerCase() === value.toLowerCase(),
    );
  }

  return undefined;
};

const AsyncAddressForm = ({
  formik,
  formPath,
  formNamesMap,
  showAdditionalInfo,
  allowedCountries,
}) => {
  const addressApiToken = useSelector(selectAddressApiToken);
  const { t } = useTranslation();
  const [defaultOptions, setDefaultOptions] = useState({});
  const formPathString = formPath ? `${formPath}.` : '';
  const {
    country: countryKey,
    zipCode: zipCodeKey,
    city: cityKey,
    street: streetKey,
    streetNr: streetNrKey,
    additionalInfo: additionalInfoKey,
    noAdditionalStreetNumber: noAdditionalStreetNumberKey,
  } = formNamesMap;

  const {
    values,
    handleBlur,
    isSubmitting,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    handleChange,
    validateField,
    initialValues,
  } = formik;

  const valuesFromPath = formPath ? get(values, formPath) : values;
  const touchedFromPath = formPath ? get(touched, formPath) : touched;
  const errorsFromPath = formPath ? get(errors, formPath) : errors;
  const initialValuesFromPath = formPath
    ? get(initialValues, formPath)
    : initialValues;

  const countriesOptions = allowedCountries ?? getCountriesOptions(t);

  const isTextZipCodeInvalid =
    (touchedFromPath?.[zipCodeKey] || touchedFromPath?.[countryKey]) &&
    !!errorsFromPath?.[zipCodeKey];

  const isAsyncZipCodeInvalid =
    touchedFromPath?.[zipCodeKey] && !!errorsFromPath?.[zipCodeKey];

  const isAsyncValidationForm =
    isCountryAutocomplete(valuesFromPath[countryKey]) && addressApiToken;

  const fetchAsyncOptions = useCallback(
    async (formValues, callback = undefined) => {
      try {
        const options = await getAsyncOptions(
          formValues,
          addressApiToken,
          formNamesMap,
        );

        if (callback) {
          callback(options);
        }

        return options;
      } catch (error) {
        return {};
      }
    },
    [addressApiToken, formNamesMap],
  );

  const checkStreetNumberKeyValidity = useCallback(
    async formValues => {
      const isStreetNumber = await checkIsStreetNumber(
        formValues,
        addressApiToken,
        formNamesMap,
      );

      setFieldValue(
        `${formPathString}${noAdditionalStreetNumberKey}`,
        isStreetNumber,
      );
    },
    [formNamesMap, formPathString, noAdditionalStreetNumberKey, setFieldValue],
  );

  useEffect(() => {
    fetchAsyncOptions(initialValuesFromPath).then(async options => {
      setDefaultOptions(options);
    });
  }, [formPath, initialValuesFromPath]);

  const resetFormValues = useCallback(() => {
    setFieldValue(`${formPathString}${zipCodeKey}`, '');
    setFieldValue(`${formPathString}${cityKey}`, '');
    setFieldValue(`${formPathString}${streetKey}`, '');
    setFieldValue(`${formPathString}${streetNrKey}`, '');
    setFieldValue(`${formPathString}${additionalInfoKey}`, '');
  }, [
    additionalInfoKey,
    cityKey,
    formPathString,
    setFieldValue,
    streetKey,
    streetNrKey,
    zipCodeKey,
  ]);

  return (
    <React.Fragment>
      <FormRow>
        <FormColumn>
          <FormControl
            label={t('global.form.country.label', 'Land')}
            hasError={
              touchedFromPath?.[countryKey] && !!errorsFromPath?.[countryKey]
            }
            message={
              touchedFromPath?.[countryKey] &&
              getErrorMessage(t, errorsFromPath?.[countryKey])
            }
          >
            <Select
              name={`${formPathString}${countryKey}`}
              isSearchable
              value={valuesFromPath[countryKey]}
              setFieldTouched={setFieldTouched}
              onBlur={handleBlur}
              placeholder={t('global.form.country.placeholder', 'Schweiz')}
              options={countriesOptions}
              disabled={isSubmitting}
              onChange={value => {
                if (
                  !isCountryAutocomplete(valuesFromPath[countryKey]) &&
                  isCountryAutocomplete(value)
                ) {
                  resetFormValues();
                  setDefaultOptions({});
                }

                setFieldValue(`${formPathString}${countryKey}`, value);
                setTimeout(() => {
                  validateField(`${formPathString}${countryKey}`);
                });
              }}
            />
          </FormControl>
        </FormColumn>
        <FormColumn>
          <PostalCodeAndCityInputsWrapper>
            <FormControl
              hasError={
                isAsyncValidationForm
                  ? isAsyncZipCodeInvalid
                  : isTextZipCodeInvalid
              }
              message={
                (isAsyncValidationForm
                  ? isAsyncZipCodeInvalid
                  : isTextZipCodeInvalid) &&
                getErrorMessage(t, errorsFromPath?.[zipCodeKey])
              }
              label={t('global.form.zipCode.label', 'PLZ')}
            >
              {isAsyncValidationForm ? (
                <Select
                  name={`${formPathString}${zipCodeKey}`}
                  isSearchable
                  value={valuesFromPath[zipCodeKey]}
                  onChange={async zipCodeValue => {
                    setFieldValue(
                      `${formPathString}${zipCodeKey}`,
                      zipCodeValue,
                    );
                    setFieldValue(`${formPathString}${streetKey}`, '');
                    setFieldValue(`${formPathString}${streetNrKey}`, '');

                    const options = await fetchAsyncOptions({
                      [zipCodeKey]: zipCodeValue,
                    });

                    const { cities, zipCodes } = options;

                    setDefaultOptions({ cities, zipCodes });

                    setFieldValue(
                      `${formPathString}${cityKey}`,
                      cities.length === 1 ? cities[0]?.value : '',
                    );
                  }}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  isAsync
                  cacheAsyncOptions={false}
                  loadOptions={async zipCode => {
                    const options = await fetchAsyncOptions({
                      [zipCodeKey]: zipCode,
                    });

                    return options.zipCodes;
                  }}
                  defaultOptions={defaultOptions.zipCodes}
                  defaultValue={getDefaultValueFromDefaultOptions(
                    'zipCodes',
                    defaultOptions,
                    valuesFromPath[zipCodeKey],
                  )}
                  placeholder={getZipCodePlaceholder(
                    valuesFromPath?.[countryKey],
                    t,
                  )}
                  withoutIcon
                  inputProps={{
                    maxLength: getZipCodeMaxLength(
                      valuesFromPath?.[countryKey],
                    ),
                    numberOnly: true,
                  }}
                />
              ) : (
                <TextInput
                  name={`${formPathString}${zipCodeKey}`}
                  value={valuesFromPath[zipCodeKey]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputMode="decimal"
                  autoComplete="postal-code"
                  disabled={isSubmitting}
                  numbersOnly
                  placeholder={getZipCodePlaceholder(
                    valuesFromPath?.[countryKey],
                    t,
                  )}
                  maxLength={getZipCodeMaxLength(valuesFromPath?.[countryKey])}
                  hasError={isTextZipCodeInvalid}
                />
              )}
            </FormControl>
            <FormControl
              noSpacing
              hasError={
                touchedFromPath?.[cityKey] && !!errorsFromPath?.[cityKey]
              }
              message={
                touchedFromPath?.[cityKey] &&
                getErrorMessage(t, errorsFromPath?.[cityKey])
              }
              label={t('global.form.city.label', 'Ort')}
            >
              {isAsyncValidationForm ? (
                <Select
                  name={`${formPathString}${cityKey}`}
                  isSearchable
                  value={valuesFromPath[cityKey]}
                  setFieldTouched={setFieldTouched}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  isAsync
                  cacheAsyncOptions={false}
                  defaultOptions={defaultOptions.cities}
                  defaultValue={getDefaultValueFromDefaultOptions(
                    'cities',
                    defaultOptions,
                    valuesFromPath[cityKey],
                  )}
                  loadOptions={async city => {
                    const options = await fetchAsyncOptions({
                      [zipCodeKey]: valuesFromPath[zipCodeKey],
                      [cityKey]: city,
                    });

                    return options.cities;
                  }}
                  placeholder={t(
                    'global.form.city.placeholder',
                    'Musterhausen',
                  )}
                  withoutIcon
                  onChange={async value => {
                    setFieldValue(`${formPathString}${streetKey}`, '');
                    setFieldValue(`${formPathString}${streetNrKey}`, '');
                    setFieldValue(`${formPathString}${cityKey}`, value);
                    setTimeout(() => {
                      validateField(`${formPathString}${cityKey}`);
                    });

                    const options = await fetchAsyncOptions({
                      [zipCodeKey]: valuesFromPath[zipCodeKey],
                      [cityKey]: value,
                    });

                    const { cities, zipCodes } = options;

                    setDefaultOptions({ cities, zipCodes });
                  }}
                />
              ) : (
                <TextInput
                  name={`${formPathString}${cityKey}`}
                  value={valuesFromPath[cityKey]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={
                    touchedFromPath?.[cityKey] && !!errorsFromPath?.[cityKey]
                  }
                  type="text"
                  placeholder={t(
                    'global.form.city.placeholder',
                    'Musterhausen',
                  )}
                  disabled={isSubmitting}
                />
              )}
            </FormControl>
          </PostalCodeAndCityInputsWrapper>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <StreetInputsWrapper>
            <FormControl
              hasError={
                touchedFromPath?.[streetKey] && !!errorsFromPath?.[streetKey]
              }
              message={
                touchedFromPath?.[streetKey] &&
                getErrorMessage(t, errorsFromPath?.[streetKey])
              }
              label={t('global.form.streetName.label', 'Strasse')}
            >
              {isAsyncValidationForm ? (
                <Select
                  name={`${formPathString}${streetKey}`}
                  isSearchable
                  value={valuesFromPath[streetKey]}
                  setFieldTouched={setFieldTouched}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  isAsync
                  cacheAsyncOptions={false}
                  defaultOptions={defaultOptions.street}
                  defaultValue={getDefaultValueFromDefaultOptions(
                    'street',
                    defaultOptions,
                    valuesFromPath[streetKey],
                  )}
                  loadOptions={async street => {
                    const options = await fetchAsyncOptions({
                      [zipCodeKey]: valuesFromPath[zipCodeKey],
                      [cityKey]: valuesFromPath[cityKey],
                      [streetKey]: street,
                    });

                    return options.street;
                  }}
                  onChange={async value => {
                    setFieldValue(`${formPathString}${streetNrKey}`, '');
                    setFieldValue(`${formPathString}${streetKey}`, value);
                    await checkStreetNumberKeyValidity({
                      ...valuesFromPath,
                      [streetKey]: value,
                    });

                    setTimeout(() => {
                      validateField(`${formPathString}${streetKey}`);
                    });

                    const options = await fetchAsyncOptions({
                      [zipCodeKey]: valuesFromPath[zipCodeKey],
                      [cityKey]: valuesFromPath[cityKey],
                      [streetKey]: value,
                    });

                    const { cities, zipCodes, street } = options;

                    setDefaultOptions({
                      cities,
                      zipCodes,
                      street,
                    });
                  }}
                  placeholder={t(
                    'global.form.streetName.placeholder',
                    'Musterstrasse',
                  )}
                  withoutIcon
                />
              ) : (
                <TextInput
                  name={`${formPathString}${streetKey}`}
                  value={valuesFromPath[streetKey]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={
                    touchedFromPath?.[streetKey] &&
                    !!errorsFromPath?.[streetKey]
                  }
                  type="text"
                  placeholder={t(
                    'global.form.streetName.placeholder',
                    'Musterstrasse',
                  )}
                  disabled={isSubmitting}
                />
              )}
            </FormControl>
            <FormControl
              hasError={
                touchedFromPath?.[streetNrKey] &&
                !!errorsFromPath?.[streetNrKey]
              }
              message={
                touchedFromPath?.[streetNrKey] &&
                getErrorMessage(t, errorsFromPath?.[streetNrKey])
              }
              label={t('global.form.streetNumber.label', 'Nr.')}
            >
              {isAsyncValidationForm ? (
                <Select
                  name={`${formPathString}${streetNrKey}`}
                  isSearchable
                  value={valuesFromPath[streetNrKey]}
                  setFieldTouched={setFieldTouched}
                  onChange={async value => {
                    setFieldValue(`${formPathString}${streetNrKey}`, value);

                    setTimeout(() => {
                      validateField(`${formPathString}${streetNrKey}`);
                    });

                    const options = await fetchAsyncOptions({
                      ...valuesFromPath,
                      [streetNrKey]: value,
                    });

                    const { cities, zipCodes, street, streetNumbers } = options;

                    setDefaultOptions({
                      cities,
                      zipCodes,
                      street,
                      streetNumbers,
                    });
                  }}
                  onBlur={handleBlur}
                  disabled={
                    isSubmitting || valuesFromPath[noAdditionalStreetNumberKey]
                  }
                  isAsync
                  cacheAsyncOptions={false}
                  defaultOptions={defaultOptions.streetNumbers}
                  defaultValue={getDefaultValueFromDefaultOptions(
                    'streetNumbers',
                    defaultOptions,
                    valuesFromPath[streetNrKey],
                  )}
                  loadOptions={async streetNr => {
                    const options = await fetchAsyncOptions({
                      ...valuesFromPath,
                      [streetNrKey]: streetNr,
                    });

                    return options.streetNumbers;
                  }}
                  placeholder={
                    !valuesFromPath[noAdditionalStreetNumberKey] &&
                    t('global.form.streetNumber.placeholder', '123a')
                  }
                  withoutIcon
                />
              ) : (
                <TextInput
                  name={`${formPathString}${streetNrKey}`}
                  value={valuesFromPath[streetNrKey]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={
                    touchedFromPath?.[streetNrKey] &&
                    !!errorsFromPath?.[streetNrKey]
                  }
                  type="text"
                  placeholder={t(
                    'global.form.streetNumber.placeholder',
                    '123a',
                  )}
                  disabled={isSubmitting}
                />
              )}
            </FormControl>
          </StreetInputsWrapper>
        </FormColumn>
        <FormColumn>
          {showAdditionalInfo && (
            <FormControl
              hasError={
                touchedFromPath?.[additionalInfoKey] &&
                !!errorsFromPath?.[additionalInfoKey]
              }
              message={
                touchedFromPath?.[additionalInfoKey] &&
                getErrorMessage(t, errorsFromPath?.[additionalInfoKey])
              }
              label={t('global.form.additionalAddress.label', 'Adresszusatz')}
              optional
            >
              <TextInput
                name={`${formPathString}${additionalInfoKey}`}
                value={valuesFromPath[additionalInfoKey]}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={
                  touchedFromPath?.[additionalInfoKey] &&
                  !!errorsFromPath?.[additionalInfoKey]
                }
                placeholder={t(
                  'global.form.additionalAddress.placeholder',
                  'Adresszusatz',
                )}
                disabled={isSubmitting}
              />
            </FormControl>
          )}
        </FormColumn>
      </FormRow>
    </React.Fragment>
  );
};

AsyncAddressForm.propTypes = {
  formik: formikShape.isRequired,
  formPath: PropTypes.string,
  formNamesMap: PropTypes.shape({
    country: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    streetNr: PropTypes.string,
    additionalInfo: PropTypes.string,
    noAdditionalStreetNumber: PropTypes.string,
  }).isRequired,
  showAdditionalInfo: PropTypes.bool,
};

AsyncAddressForm.defaultProps = {
  formPath: '',
  showAdditionalInfo: true,
};

export default AsyncAddressForm;
