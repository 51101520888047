import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  getThemeColor,
  getThemeColorWithError,
  getThemePath,
  getThemeTransition,
} from '@utils/styled';

export const Container = styled.div`
  position: relative;
  flex: 1 1 auto;
  width: auto;
  transition: ${getThemeTransition('all')};
  ${({ widthDefaultSpacing, theme }) =>
    widthDefaultSpacing
      ? theme.getFluidSpacing('margin-left', 'equal', 5)
      : null}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('padding', 'equal', 6)};
`;

const selectStyles = css`
  &.Select--is-disabled {
    pointer-events: auto;
    cursor: not-allowed;
  }

  .Select__control {
    z-index: 1;
    width: 100%;
    height: 5.1rem;
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    border-color: ${getThemeColorWithError('bluegrey.80', 'hue.red')};
    border-radius: ${getThemePath(['borderRadiuses', 'normal.1'])}rem;
    padding: 0;
    appearance: none;
    background-color: ${getThemeColorWithError('grey.100', 'hue.errorYellow')};
    ${({ theme }) => theme.getFluidFont('body1')};
    color: ${getThemeColorWithError('bluegrey.15', 'hue.red')};
    line-height: 5.1rem;
    cursor: pointer;

    &--menu-is-open {
      box-shadow: ${({ theme }) => theme.shadows[0]};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--no-value {
      border-bottom-right-radius: ${getThemePath([
        'borderRadiuses',
        'normal.1',
      ])}rem;
      border-bottom-left-radius: ${getThemePath([
        'borderRadiuses',
        'normal.1',
      ])}rem;
    }

    &.Select__control--is-disabled {
      opacity: 1;
      border-color: ${getThemeColor('bluegrey.95')};
      background-color: ${getThemeColor('bluegrey.95')};
      color: ${getThemeColorWithError('bluegrey.15', 'hue.red')};
      cursor: not-allowed;
    }

    &.Select__control--is-focused:not(.Select--is-disabled),
    &:hover:not(.Select--is-disabled) {
      border-color: ${getThemeColorWithError('bluegrey.60', 'hue.red')};
    }

    &.Select__control--menu-is-open:not(.Select--is-disabled) {
      box-shadow: ${({ theme }) => theme.shadows[0]};
      border-color: transparent;
    }
  }

  .Select__placeholder {
    opacity: 1;
    color: ${getThemeColor('bluegrey.80')};
  }

  .Select__value-container {
    overflow: visible;
    max-height: 100%;
    padding: 0 0 0 2rem;
    ${({ theme }) => theme.getFluidFont('paragraph2')};

    & > div {
      overflow: hidden;
      box-sizing: border-box;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .Select__input {
    top: 0;
    width: 100%;
    max-height: 100%;
  }

  .Select__input-container {
    display: flex;

    &::after {
      content: none;
      display: none;
    }
  }

  .Select__single-value {
    color: ${getThemeColorWithError('bluegrey.15', 'hue.red')};
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__dropdown-indicator {
    ${({ withoutIcon }) =>
      withoutIcon
        ? null
        : css`
            position: relative;
            width: 4rem;
            height: 100%;
            padding: 0;

            &::after {
              content: '';
              position: absolute;
              top: calc(50% - 2px);
              right: 1.4rem;
              display: block;
              border-top: 6px solid ${getThemeColor('bluegrey.15')};
              border-right: 6px solid transparent;
              border-left: 6px solid transparent;
              transition: ${getThemeTransition('transform')};
              transform: rotate(0);
            }
          `}
    svg {
      display: none;
    }
  }

  .Select__control--menu-is-open .Select__dropdown-indicator::after {
    transform: rotate(180deg);
  }

  .Select__option {
    border-radius: ${getThemePath(['borderRadiuses', 'normal.1'])}rem;
    padding: 10px 15px;
    cursor: pointer;

    &.Select__option--is-focused {
      background-color: ${getThemeColor('bluegrey.95')};
    }

    &.Select__option--is-selected {
      background-color: ${getThemeColor('grey.100')};
      color: ${getThemeColor('bluegrey.15')};
      font-weight: bold;

      &:hover {
        background-color: ${getThemeColor('bluegrey.95')};
      }
    }
  }

  .Select__menu {
    z-index: 2;
    box-shadow: ${({ theme }) => theme.shadows[0]};
    margin-top: 0;
    border-radius: ${getThemePath(['borderRadiuses', 'normal.1'])}rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top-color: ${getThemeColor('bluegrey.90')};
    padding: 0.5rem;

    &-list {
      padding: 0;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        border-radius: ${getThemePath(['borderRadiuses', 'normal.1'])}rem;
        background: ${getThemeColor('bluegrey.90')};
      }

      &::-webkit-scrollbar-thumb {
        border-radius: ${getThemePath(['borderRadiuses', 'normal.1'])}rem;
        background: ${getThemeColor('bluegrey.80')};
      }
    }
  }
`;

export const StyledSelect = styled(ReactSelect)`
  ${selectStyles};
`;

export const StyledAsyncSelect = styled(AsyncSelect)`
  ${selectStyles};

  .Select__indicators {
    display: none;
  }

  .Select__control--is-focused {
    .Select__single-value {
      display: none;
    }
  }
`;

export const NoOptionsMessageContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: flex-start;

  .Select__menu-notice {
    text-align: start;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
`;
