import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { components } from 'react-select';

import LoadingIndicator from '@common/components/LoadingIndicator';

import {
  Container,
  LoaderWrapper,
  NoOptionsMessageContainer,
  StyledAsyncSelect,
  StyledSelect,
} from './Select.styled';

const getDefaultAsyncValue = (defaultValue, value) => {
  if (defaultValue) {
    return defaultValue;
  }

  if (value === '') {
    return '';
  }

  return undefined;
};

const NoOptionsMessage = ({ ...props }) =>
  props.selectProps.inputValue ? (
    <NoOptionsMessageContainer>
      <components.NoOptionsMessage {...props} />
    </NoOptionsMessageContainer>
  ) : null;
const Menu = ({ ...props }) =>
  props.selectProps.inputValue || props.selectProps.options.length ? (
    <components.Menu {...props}>{props.children}</components.Menu>
  ) : null;

const Control = ({ ...props }) => (
  <components.Control
    {...props}
    className={
      !props.selectProps.inputValue &&
      !props.selectProps.options.length &&
      'Select__control--no-value'
    }
  >
    {props.children}
  </components.Control>
);

NoOptionsMessage.propTypes = {
  hasValue: PropTypes.bool,
  children: PropTypes.node,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
  }).isRequired,
};

NoOptionsMessage.defaultProps = {
  hasValue: false,
  children: null,
};

Menu.propTypes = {
  hasValue: PropTypes.bool,
  children: PropTypes.node,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
        ]),
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
        ]),
      }),
    ),
  }).isRequired,
};

Control.propTypes = {
  hasValue: PropTypes.bool,
  children: PropTypes.node,
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
        ]),
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
        ]),
      }),
    ),
  }).isRequired,
};

Menu.defaultProps = {
  hasValue: false,
  children: null,
};

Control.defaultProps = {
  hasValue: false,
  children: null,
};

const Select = ({
  options,
  setFieldValue,
  name,
  value,
  widthDefaultSpacing,
  className,
  disabled,
  setFieldTouched,
  isAsync,
  loadOptions,
  onChange,
  cacheAsyncOptions,
  defaultValue,
  defaultOptions,
  inputProps,
  ...props
}) => {
  const { t } = useTranslation();
  const isAsyncSelect = isAsync && loadOptions;
  const selectedOption = isAsyncSelect
    ? getDefaultAsyncValue(defaultValue, value)
    : options.find(option => option.value === value);
  const asyncRef = useRef();
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback(
    async option => {
      const optionValue = option?.value;

      setInputValue('');

      if (setFieldValue) {
        setFieldValue(name, optionValue);
      }

      if (onChange) {
        await onChange(optionValue);
      }

      if (setFieldTouched) {
        setTimeout(() => {
          setFieldTouched(name);
        }, 0);
      }
    },
    [name, onChange, setFieldTouched, setFieldValue],
  );

  const commonOptions = useMemo(
    () => ({
      value: selectedOption,
      noOptionsMessage: () => t('select.noOptionMessage', 'keine optionen'),
      onChange: handleChange,
      isDisabled: disabled,
      autoComplete: 'off',
      name,
      instanceId: name,
      ...props,
      onBlur: args => {
        props?.onBlur(args);
        setInputValue('');
      },
    }),
    [disabled, handleChange, name, props, selectedOption, t, setInputValue],
  );

  return (
    <Container widthDefaultSpacing={widthDefaultSpacing} className={className}>
      {isAsyncSelect ? (
        <StyledAsyncSelect
          blurInputOnSelect
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          cacheOptions={cacheAsyncOptions}
          ref={asyncRef}
          components={{
            NoOptionsMessage,
            LoadingMessage: () => (
              <LoaderWrapper>
                <LoadingIndicator />
              </LoaderWrapper>
            ),
            Menu,
            Control,
          }}
          onFocus={() => {
            setInputValue(selectedOption?.label || value);
          }}
          onInputChange={(changeValue, { action }) => {
            if (action === 'input-change') {
              setInputValue(changeValue);
            }
          }}
          inputValue={inputValue}
          {...commonOptions}
        />
      ) : (
        <StyledSelect options={options} {...commonOptions} />
      )}
    </Container>
  );
};

Select.defaultProps = {
  children: undefined,
  className: undefined,
  classNamePrefix: 'Select',
  disabled: false,
  isSearchable: false,
  setFieldValue: undefined,
  value: undefined,
  setFieldTouched: undefined,
  widthDefaultSpacing: false,
  isAsync: false,
  loadOptions: undefined,
  options: [],
  defaultOptions: [],
  onChange: undefined,
  cacheAsyncOptions: true,
  defaultValue: undefined,
  maxLength: undefined,
  withoutIcon: false,
  inputProps: {},
};

Select.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
    }),
  ),
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
    }),
  ),
  inputProps: PropTypes.shape({
    maxLength: PropTypes.number,
    numberOnly: PropTypes.bool,
  }),
  setFieldValue: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  setFieldTouched: PropTypes.func,
  widthDefaultSpacing: PropTypes.bool,
  isAsync: PropTypes.bool,
  cacheAsyncOptions: PropTypes.bool,
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  }),
  maxLength: PropTypes.number,
  withoutIcon: PropTypes.bool,
};

export { Select };
