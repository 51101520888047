import React from 'react';
import PropTypes from 'prop-types';

import {
  Field,
  FieldCheckIcon,
  FieldMinusIcon,
  Input,
  Label,
  LabelText,
} from './SelectionControl.styled';

export const SelectionControl = ({
  className,
  disabled,
  id,
  label,
  checked,
  onChange,
  value,
  type,
  variant,
  spacingTop,
  spacingBottom,
  fieldSpacingTop,
  fieldSpacingRight,
  isDeselect,
  alignment,
  ...props
}) => (
  <Label
    htmlFor={id}
    className={className}
    disabled={disabled}
    spacingTop={spacingTop}
    spacingBottom={spacingBottom}
    alignment={alignment}
  >
    <Input
      id={id}
      type={type}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      value={value}
      {...props}
    />
    <Field
      type={type}
      spacingRight={fieldSpacingRight}
      spacingTop={fieldSpacingTop}
    >
      {!isDeselect ? <FieldCheckIcon type={type} /> : <FieldMinusIcon />}
    </Field>
    {label && <LabelText variant={variant}>{label}</LabelText>}
  </Label>
);

SelectionControl.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  variant: PropTypes.string,
  isDeselect: PropTypes.bool,
  alignment: PropTypes.string,
  spacingTop: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  spacingBottom: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  fieldSpacingTop: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  fieldSpacingRight: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

SelectionControl.defaultProps = {
  type: 'checkbox',
  checked: undefined,
  className: undefined,
  disabled: false,
  id: undefined,
  label: undefined,
  onChange: undefined,
  value: undefined,
  variant: 'paragraph1',
  spacingTop: undefined,
  spacingBottom: undefined,
  fieldSpacingTop: undefined,
  fieldSpacingRight: undefined,
  isDeselect: false,
  alignment: 'center',
};

export default SelectionControl;
