import styled from 'styled-components';

import {
  getThemeColor,
  getThemeColorWithError,
  getThemePath,
  getThemeTransition,
} from '@utils/styled';

export const TextareaElement = styled.textarea`
  width: 100%;
  min-height: 4.8rem;
  box-sizing: content-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${getThemeColorWithError(
    'bluegrey.80',
    'hue.red',
    '$hasError',
  )};
  border-radius: ${getThemePath(['borderRadiuses', 'normal.1'])}rem;
  padding: 1.5rem 1.8rem;
  background-color: ${getThemeColorWithError(
    'grey.100',
    'hue.errorYellow',
    '$hasError',
  )};
  color: ${getThemeColorWithError('bluegrey.15', 'hue.red', '$hasError')};
  font-size: 2rem;
  line-height: 1.2;
  transition: ${getThemeTransition('border-color')};
  ${({ $noUserResize }) => $noUserResize && 'resize: none;'}

  &:disabled {
    border-color: ${getThemeColor('bluegrey.95')};
    background-color: ${getThemeColor('bluegrey.95')};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    border-color: ${getThemeColorWithError(
      'bluegrey.60',
      'hue.red',
      '$hasError',
    )};
  }

  &:focus:not(:disabled) {
    outline: none;
    border-color: ${getThemeColorWithError(
      'bluegrey.15',
      'hue.red',
      '$hasError',
    )};
  }

  &::placeholder {
    opacity: 1;
    color: ${getThemeColor('bluegrey.80')};
  }
`;
