import { COUNTRY_PHONE_PREFIXES } from '@common/constants/countries';

export const extractPhoneParts = (fullPhoneNumber = '') => {
  let prefix = '';

  const prefixFound = COUNTRY_PHONE_PREFIXES.find(countryPrefix =>
    fullPhoneNumber.startsWith(countryPrefix),
  );

  if (prefixFound) {
    prefix = fullPhoneNumber.slice(0, prefixFound.length);
  }
  const phone = fullPhoneNumber.slice(prefix.length);

  return { phone, prefix };
};
