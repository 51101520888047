import React from 'react';
import PropTypes from 'prop-types';

import { SIZES } from './constants';
import { Input, Label, TextWrapper } from './RadioButton.styled';

export function RadioButton({
  size,
  checked,
  disabled,
  id,
  name,
  onChange,
  text,
  isImage,
  'data-testid': dataTestid,
  ...props
}) {
  return (
    <Label disabled={disabled} htmlFor={id} data-testid={dataTestid}>
      <Input
        checked={checked}
        data-testid={dataTestid && `${dataTestid}-input`}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        size={size}
        {...props}
      />
      <TextWrapper size={size} isImage={isImage}>
        {text}
      </TextWrapper>
    </Label>
  );
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(Object.values(SIZES)),
  text: PropTypes.node.isRequired,
  isImage: PropTypes.bool,
};

RadioButton.defaultProps = {
  checked: false,
  'data-testid': undefined,
  disabled: false,
  id: undefined,
  onChange: () => {},
  size: SIZES.NORMAL,
  isImage: false,
};

export default RadioButton;
