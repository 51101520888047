import * as Yup from 'yup';

import {
  REQUIRED_FIELD_ERROR,
  INVALID_FOUR_ZIPCODE_ERROR,
  INVALID_FIVE_ZIPCODE_ERROR,
} from '@common/constants/formValidation';
import {
  COUNTRY_GERMANY,
  COUNTRY_FRANCE,
  COUNTRY_ITALY,
} from '@common/constants/countries';

export const requiredAddressSchema = {
  gender: Yup.string().required(REQUIRED_FIELD_ERROR),
  firstname: Yup.string().required(REQUIRED_FIELD_ERROR),
  lastname: Yup.string().required(REQUIRED_FIELD_ERROR),
  street: Yup.string().required(REQUIRED_FIELD_ERROR),
  houseNr: Yup.string().when('noAdditionalStreetNumber', {
    is: false,
    then: Yup.string().required(REQUIRED_FIELD_ERROR),
    otherwise: Yup.string(),
  }),
  additionalAddress: Yup.string(),
  country: Yup.string().required(REQUIRED_FIELD_ERROR),
  zipCode: Yup.string()
    .required(REQUIRED_FIELD_ERROR)
    .when('country', {
      is: country =>
        [COUNTRY_GERMANY, COUNTRY_FRANCE, COUNTRY_ITALY].includes(country),
      otherwise: Yup.string().test({
        message: INVALID_FOUR_ZIPCODE_ERROR,
        name: 'zipCodeLengthFour',
        test: value => value && value.length === 4,
      }),
      then: Yup.string().test({
        message: INVALID_FIVE_ZIPCODE_ERROR,
        name: 'zipCodeLengthFive',
        test: value => value && value.length === 5,
      }),
    }),
  city: Yup.string().required(REQUIRED_FIELD_ERROR),
  noAdditionalStreetNumber: Yup.boolean().default(false),
};
