import { useEffect, useState } from 'react';

import { getFormattedMaskValue } from '@utils/maskedInput';

export const useDataPlaceholder = (isBirthdayField, value, mask) => {
  const [dataPlaceholder, setDataPlaceholder] = useState('');

  useEffect(() => {
    if (isBirthdayField) {
      setDataPlaceholder(value);
    } else {
      setDataPlaceholder(getFormattedMaskValue(value, mask));
    }
  }, [value]);

  return [dataPlaceholder, setDataPlaceholder];
};
