import styled from 'styled-components';

import { media } from '@utils/styled';

export const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ hasFiveInLine }) =>
    hasFiveInLine ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'};
  grid-gap: ${({ hasFiveInLine }) => (hasFiveInLine ? '1rem' : '0rem')};
  ${({ theme, spacingTop }) =>
    spacingTop && theme.getFluidSpacing('margin-top', ...spacingTop)};
  ${({ theme, spacingBottom }) =>
    spacingBottom && theme.getFluidSpacing('margin-bottom', ...spacingBottom)};

  ${media.from800up`
    grid-template-columns: ${({ hasFiveInLine }) =>
      hasFiveInLine ? 'repeat(5, 1fr)' : 'repeat(2, minmax(0, 1fr))'};
    ${({ hasFiveInLine, theme }) =>
      hasFiveInLine
        ? 'grid-gap: 2.5rem'
        : theme.getFluidSpacing('grid-gap', 'widthBased', 1)};
  `}

  & > div {
    ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 5)}
  }
`;

export const PostalCodeAndCityInputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 11.1rem 1fr;
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'equal', 5)};
`;

export const StreetInputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 10rem;
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'equal', 5)};
`;
