import PropTypes from 'prop-types';
import React from 'react';
import { IMaskMixin } from 'react-imask';

import {
  FORBIDDEN_KEYCODES,
  MASKED_INPUT_TYPE,
} from '@common/constants/maskedInput';
import { useDataPlaceholder } from '@common/hooks/useDataPlaceholder';

import {
  Input,
  InputPlaceholder,
  InputWrapper,
  Label,
  LabelRow,
  LabelText,
  OptionalText,
} from './TextInputMasked.styled';

const MaskedInput = IMaskMixin(({ inputRef, maskedInputType, ...props }) => (
  <Input
    ref={inputRef}
    type="text"
    inputMode={maskedInputType !== MASKED_INPUT_TYPE.TEXT && 'numeric'}
    {...props}
  />
));

const handleKeyDown = e => {
  if (FORBIDDEN_KEYCODES.includes(e.keyCode)) {
    e.preventDefault();
  }
};

export const TextInputMasked = ({
  className,
  label,
  hasError,
  optional,
  optionalText,
  value,
  onChange,
  widthDefaultSpacing,
  id,
  name,
  mask,
  fixedPrefix,
  maskedInputType,
  ...props
}) => {
  const [dataPlaceholder] = useDataPlaceholder(
    maskedInputType === MASKED_INPUT_TYPE.DATE,
    value,
    mask,
  );

  if (label) {
    return (
      <Label
        className={className}
        widthDefaultSpacing={widthDefaultSpacing}
        htmlFor={id}
      >
        <LabelRow>
          {typeof label === 'string' ? (
            <LabelText hasError={hasError}>{label}</LabelText>
          ) : (
            label
          )}
          {optional && (
            <OptionalText hasError={hasError}>{optionalText}</OptionalText>
          )}
        </LabelRow>
        <InputWrapper
          isPhoneNumberField={maskedInputType === MASKED_INPUT_TYPE.PHONE}
          hasError={hasError}
          fixedPrefix={fixedPrefix}
        >
          {dataPlaceholder && (
            <InputPlaceholder contentEditable={true}>
              {dataPlaceholder}
            </InputPlaceholder>
          )}
          <MaskedInput
            {...props}
            hasError={hasError}
            onAccept={onChange}
            value={value}
            id={id}
            onKeyDown={handleKeyDown}
            onChange={undefined}
            asPlaceholder={!value.replace(/ /g, '')}
            maskedInputType={maskedInputType}
            name={name}
            mask={mask}
          />
        </InputWrapper>
      </Label>
    );
  }

  return (
    <InputWrapper className={className}>
      {dataPlaceholder && (
        <InputPlaceholder
          $isPhoneNumberField={maskedInputType === MASKED_INPUT_TYPE.PHONE}
          $fixedPrefix={fixedPrefix}
        >
          <span>{dataPlaceholder}</span>
        </InputPlaceholder>
      )}
      <MaskedInput
        hasError={hasError}
        widthDefaultSpacing={widthDefaultSpacing}
        onAccept={onChange}
        id={id}
        {...props}
        value={value}
        onKeyDown={handleKeyDown}
        asPlaceholder={!value.replace(/ /g, '')}
        maskedInputType={maskedInputType}
        name={name}
        mask={mask}
      />
    </InputWrapper>
  );
};

TextInputMasked.defaultProps = {
  maskedInputType: MASKED_INPUT_TYPE.TEXT,
  className: undefined,
  hasError: false,
  id: undefined,
  label: undefined,
  numbersOnly: false,
  onChange: undefined,
  optional: false,
  optionalText: 'optional',
  placeholder: null,
  type: 'text',
  value: undefined,
  widthDefaultSpacing: false,
  mask: undefined,
  fixedPrefix: undefined,
};

TextInputMasked.propTypes = {
  maskedInputType: PropTypes.oneOf(Object.values(MASKED_INPUT_TYPE)),
  className: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  numbersOnly: PropTypes.bool,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  optionalText: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  widthDefaultSpacing: PropTypes.bool,
  name: PropTypes.string.isRequired,
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([Date, Number]),
  ]),
  fixedPrefix: PropTypes.string,
};
