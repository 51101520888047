import {
  COUNTRY_GERMANY,
  COUNTRY_FRANCE,
  COUNTRY_ITALY,
} from '@common/constants/countries';

export const getZipCodeMaxLength = country => {
  switch (country) {
    case COUNTRY_GERMANY:
    case COUNTRY_FRANCE:
    case COUNTRY_ITALY:
      return '5';
    default:
      return '4';
  }
};

export const getZipCodePlaceholder = (country, t) => {
  switch (country) {
    case COUNTRY_GERMANY:
    case COUNTRY_FRANCE:
    case COUNTRY_ITALY:
      return t('global.form.zipCode.placeholder5digits', '12345');
    default:
      return t('global.form.zipCode.placeholder4digits', '1234');
  }
};
