import styled from 'styled-components';

import { getThemeColor } from '@utils/styled';

import Typography from '@common/components/Typography';

export const AdditionalInfo = styled(Typography).attrs({ variant: 'body2' })`
  ${({ theme, spacingBottom }) =>
    spacingBottom && theme.getFluidSpacing('margin-bottom', ...spacingBottom)};
  ${({ theme, spacingTop }) =>
    spacingTop
      ? theme.getFluidSpacing('margin-top', ...spacingTop)
      : theme.getFluidSpacing('margin-top', 'equal', 8)};
  color: ${getThemeColor('bluegrey.70')};
`;

export const AdditionalInfoWrapper = styled.div`
  ${({ theme, spacingTop }) =>
    spacingTop && theme.getFluidSpacing('margin-top', ...spacingTop)};
  ${({ theme, spacingBottom }) =>
    spacingBottom && theme.getFluidSpacing('margin-bottom', ...spacingBottom)};
`;
