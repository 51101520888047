import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

import { getErrorMessage } from '@utils/validation';

import {
  COUNTRY_SWITZERLAND,
  getCountriesOptions,
} from '@common/constants/countries';
import { GENDER_FEMALE, GENDER_MALE } from '@common/constants/genders';
import {
  FormControl,
  InputsWrapper,
  RadioButton,
  RadiosWrapper,
  TextInput,
} from '@common/components/forms';
import AsyncAddressForm from '@common/components/forms/AsyncAddressForm';

const AddressSection = ({ fieldPath, showEmailInput }) => {
  const { t } = useTranslation();
  const countriesOptions = getCountriesOptions(t, [COUNTRY_SWITZERLAND]);
  const formik = useFormikContext();

  const {
    values: formikValues,
    handleBlur,
    isSubmitting,
    touched: formikTouched,
    errors: formikErrors,
    handleChange,
  } = formik;

  const fieldPathDot = fieldPath ? `${fieldPath}.` : '';

  const commonProps = {
    onChange: handleChange,
    onBlur: handleBlur,
    disabled: isSubmitting,
  };

  const values = fieldPath ? get(formikValues, fieldPath) : formikValues;
  const touched = fieldPath ? get(formikTouched, fieldPath) : formikTouched;
  const errors = fieldPath ? get(formikErrors, fieldPath) : formikErrors;

  return (
    <React.Fragment>
      <InputsWrapper spacingTop={['scale', 3]}>
        <FormControl
          label={t('global.form.gender.label', 'Anrede')}
          labelAs="span"
          hasError={touched?.gender && !!errors?.gender}
          message={touched?.gender && getErrorMessage(t, errors?.gender)}
        >
          <RadiosWrapper>
            <RadioButton
              {...commonProps}
              value={GENDER_FEMALE}
              checked={values.gender === GENDER_FEMALE}
              name={`${fieldPathDot}gender`}
              text={t('global.form.gender.OptionFemale', 'Frau')}
            />
            <RadioButton
              {...commonProps}
              value={GENDER_MALE}
              checked={values.gender === GENDER_MALE}
              name={`${fieldPathDot}gender`}
              text={t('global.form.gender.OptionMale', 'Herr')}
            />
          </RadiosWrapper>
        </FormControl>
      </InputsWrapper>

      <InputsWrapper>
        <FormControl
          label={t('global.form.firstName.label', 'Vorname')}
          hasError={touched?.firstname && !!errors?.firstname}
          message={touched?.firstname && getErrorMessage(t, errors?.firstname)}
        >
          <TextInput
            {...commonProps}
            value={values.firstname}
            hasError={touched?.firstname && !!errors?.firstname}
            name={`${fieldPathDot}firstname`}
            type="text"
            placeholder={t('global.form.firstName.placeholder', 'Doris')}
          />
        </FormControl>
        <FormControl
          label={t('global.form.lastName.label', 'Nachname')}
          hasError={touched?.lastname && !!errors?.lastname}
          message={touched?.lastname && getErrorMessage(t, errors?.lastname)}
        >
          <TextInput
            {...commonProps}
            value={values.lastname}
            hasError={touched?.lastname && !!errors?.lastname}
            name={`${fieldPathDot}lastname`}
            type="text"
            placeholder={t('global.form.lastName.placeholder', 'Mustermann')}
          />
        </FormControl>
      </InputsWrapper>
      <AsyncAddressForm
        allowedCountries={countriesOptions}
        formPath={fieldPath}
        formNamesMap={{
          country: 'country',
          zipCode: 'zipCode',
          city: 'city',
          street: 'street',
          streetNr: 'houseNr',
          additionalInfo: 'additionalAddress',
          noAdditionalStreetNumber: 'noAdditionalStreetNumber',
        }}
        formik={formik}
      />

      {showEmailInput && (
        <InputsWrapper>
          <FormControl
            hasError={touched?.email && !!errors?.email}
            message={touched?.email && getErrorMessage(t, errors?.email)}
            label={t('global.form.email.label', 'E-Mail Adresse')}
          >
            <TextInput
              {...commonProps}
              value={values.email}
              hasError={touched?.email && !!errors?.email}
              name={`${fieldPathDot}email`}
              type="email"
              inputMode="email"
              placeholder={t(
                'global.form.email.placeholder',
                'doris.mustermann@mail.ch',
              )}
            />
          </FormControl>
        </InputsWrapper>
      )}
    </React.Fragment>
  );
};

AddressSection.propTypes = {
  fieldPath: PropTypes.string,
  showEmailInput: PropTypes.bool,
};

AddressSection.defaultProps = {
  fieldPath: undefined,
  showEmailInput: false,
};

export default AddressSection;
