import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import { extractPhoneParts } from '@utils/phone';

import * as Icons from '@common/components/Icons';
import {
  COUNTRY_CIRCLE_FLAGS,
  COUNTRY_PHONE_PREFIXES_MAP,
  PHONES_OPTIONS_ORDER,
  PHONE_PREFIX_TO_MASK_AND_PLACEHOLDER_MAP,
  getCountriesForPrefix,
} from '@common/constants/countries';
import { MASKED_INPUT_TYPE } from '@common/constants/maskedInput';
import { Select } from '@common/components/forms';

import {
  Container,
  NumberTextInput,
  PrefixOption,
  FixedPrefix,
  PrefixWrapper,
} from './PhoneNumberInput.styled';

const useDialCodes = () => {
  const { t } = useTranslation();

  return PHONES_OPTIONS_ORDER.map(country => {
    const FlagIcon = Icons[COUNTRY_CIRCLE_FLAGS[country]];
    const countryNameForOption = getCountriesForPrefix(t)[country];

    return {
      label: (
        <PrefixOption>
          <FlagIcon width={14} height={14} />
          {COUNTRY_PHONE_PREFIXES_MAP[country]}
        </PrefixOption>
      ),
      value: COUNTRY_PHONE_PREFIXES_MAP[country],
      country: countryNameForOption,
    };
  });
};

export const PhoneNumberInput = ({
  className,
  name,
  setFieldValue,
  value,
  disabled,
  prefixFieldName,
  numberFieldName,
  prefixFieldId,
  numberFieldId,
  hasError,
  onBlur,
  fixedPrefix,
}) => {
  const { prefix, phone } = extractPhoneParts(value);
  const options = useDialCodes();

  const handlePrefixChange = (_, selectedOption) => {
    const newValue = `${selectedOption}${phone}`;

    if (setFieldValue && name) {
      setFieldValue(name, newValue);
    }

    if (setFieldValue && prefixFieldName) {
      setFieldValue(prefixFieldName, selectedOption);
    }
  };

  const handleNumberChange = number => {
    const numberInput = number.replace(/^0+/, '');

    const newValue = `${prefix}${numberInput}`;

    if (setFieldValue && name) {
      setFieldValue(name, newValue);
    }

    if (setFieldValue && numberFieldName) {
      setFieldValue(numberFieldName, number);
    }
  };

  const { mask, placeholder } =
    PHONE_PREFIX_TO_MASK_AND_PLACEHOLDER_MAP[fixedPrefix || prefix];
  const fixedPrefixOption = useMemo(
    () => options.find(option => option.value === fixedPrefix),
    [fixedPrefix],
  );

  useEffect(() => {
    const phoneMaxLength = mask.split('').filter(char => char !== ' ').length;

    handleNumberChange(phone.slice(0, phoneMaxLength));
  }, [mask, prefix]);

  return (
    <Container className={className}>
      <PrefixWrapper fixedPrefix={fixedPrefix}>
        {fixedPrefix ? (
          <FixedPrefix hasError={hasError}>
            {fixedPrefixOption.label}
          </FixedPrefix>
        ) : (
          <Select
            isSearchable
            name={prefixFieldName}
            id={prefixFieldId}
            options={options}
            value={prefix}
            setFieldValue={handlePrefixChange}
            onBlur={onBlur}
            disabled={disabled}
            hasError={hasError}
            filterOption={(option, searchText) => {
              const {
                data: { value: optionValue, country },
              } = option;

              const optionCountry = country.toLowerCase();
              const searchTextLower = searchText.toLowerCase();
              const optionValueLower = optionValue.toLowerCase();

              return (
                optionValueLower.includes(searchTextLower) ||
                optionCountry.includes(searchTextLower)
              );
            }}
          />
        )}
      </PrefixWrapper>
      <NumberTextInput
        name={numberFieldName}
        id={numberFieldId}
        value={phone}
        mask={mask}
        placeholderChar="_"
        placeholder={placeholder}
        unmask
        lazy={!phone}
        overwrite
        autofix
        onChange={handleNumberChange}
        disabled={disabled}
        maskedInputType={MASKED_INPUT_TYPE.PHONE}
        onBlur={onBlur}
        novalidate
        hasError={hasError}
        fixedPrefix={fixedPrefix}
        type="tel"
        inputMode="tel"
        key={prefix}
      />
    </Container>
  );
};

PhoneNumberInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  name: PropTypes.string,
  numberFieldId: PropTypes.string,
  numberFieldName: PropTypes.string,
  prefixFieldId: PropTypes.string,
  prefixFieldName: PropTypes.string,
  setFieldValue: PropTypes.func,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  fixedPrefix: PropTypes.string,
};

PhoneNumberInput.defaultProps = {
  className: undefined,
  disabled: false,
  hasError: false,
  name: undefined,
  numberFieldId: undefined,
  numberFieldName: undefined,
  prefixFieldId: undefined,
  prefixFieldName: undefined,
  setFieldValue: undefined,
  fixedPrefix: null,
  onBlur: () => {},
  value: '',
};
