import styled from 'styled-components';

import Typography from '@common/components/Typography';

export const SectionHeading = styled(Typography).attrs({ variant: 'h4' })`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 3)};
  ${({ theme, spacingTop }) =>
    spacingTop && theme.getFluidSpacing('margin-top', ...spacingTop)};
  ${({ theme, spacingBottom }) =>
    spacingBottom && theme.getFluidSpacing('margin-bottom', ...spacingBottom)};
`;

export const SectionHeadingSmall = styled(SectionHeading).attrs({
  variant: 'h5',
})``;

export const SubSectionHeading = styled(SectionHeading).attrs({
  variant: 'h6',
})``;
