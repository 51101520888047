import styled from 'styled-components';

import { getGridBase, media } from '@utils/styled';

export const FormRow = styled.div`
  ${getGridBase(8)}
`;

export const FormColumn = styled.div`
  grid-column: span 8;

  ${media.from800up`
    grid-column: span 4;
  `}
`;
