import styled from 'styled-components';

import { getThemeColor, getThemePath, getThemeTransition } from '@utils/styled';

import Typography from '@common/components/Typography';
import {
  Check as CheckIconSvg,
  Minus as MinusSignIconSvg,
} from '@common/components/Icons';

const TYPES = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};

const TYPE_TO_BORDER_RADIUS = {
  [TYPES.RADIO]: () => '50%',
  [TYPES.CHECKBOX]: theme =>
    `${getThemePath(['borderRadiuses', 'normal.1'])({ theme })}rem`,
};

const TYPE_TO_ICON_WIDTH = {
  [TYPES.RADIO]: '1.2rem',
  [TYPES.CHECKBOX]: '1.4rem',
};

const TYPE_TO_ICON_HEIGHT = {
  [TYPES.RADIO]: '0.9rem',
  [TYPES.CHECKBOX]: '1.1rem',
};

export const Label = styled.label`
  display: inline-flex;
  align-items: ${({ alignment }) => alignment};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ theme, spacingTop }) =>
    spacingTop && theme.getFluidSpacing('margin-top', ...spacingTop)};
  ${({ theme, spacingBottom }) =>
    spacingBottom && theme.getFluidSpacing('margin-bottom', ...spacingBottom)};
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Field = styled.div`
  display: inline-flex;
  flex: 0 0 2.4rem;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  ${({ theme, spacingTop }) =>
    spacingTop
      ? theme.getFluidSpacing('margin-top', ...spacingTop)
      : 'margin-top: 0.3rem;'};
  ${({ theme, spacingRight }) =>
    spacingRight
      ? theme.getFluidSpacing('margin-right', ...spacingRight)
      : 'margin-right: 2rem;'};
  border: 1px solid ${getThemeColor('bluegrey.70')};
  border-radius: ${({ theme, type }) => TYPE_TO_BORDER_RADIUS[type](theme)};
  padding-top: 1px;
  background-color: transparent;
  transition: ${getThemeTransition('all')};

  input:focus + &,
  input:hover + &,
  input:active + & {
    border-color: ${getThemeColor('bluegrey.15')};
  }

  input:checked + & {
    border-color: transparent;
    background-color: ${getThemeColor('hue.green')};
  }

  input:checked:focus + &,
  input:checked:hover + &,
  input:checked:active + & {
    border-color: transparent;
    background-color: ${getThemeColor('hue.darkGreen')};
  }
`;

export const FieldCheckIcon = styled(CheckIconSvg)`
  fill: transparent;
  width: ${({ type }) => TYPE_TO_ICON_WIDTH[type]};
  height: ${({ type }) => TYPE_TO_ICON_HEIGHT[type]};
  transition: ${getThemeTransition('fill')};

  input:checked + ${/* sc-sel */ Field} > & {
    fill: ${getThemeColor('grey.100')};
  }
`;

export const FieldMinusIcon = styled(MinusSignIconSvg)`
  fill: transparent;
  width: 1.4rem;
  height: 1.4rem;
  transition: ${getThemeTransition('fill')};

  input:checked + ${/* sc-sel */ Field} > & {
    fill: ${getThemeColor('grey.100')};
  }
`;

export const LabelText = styled(Typography).attrs(({ variant }) => ({
  customAs: 'span',
  variant,
}))`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;
