import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'next-i18next';

import { TextInputMasked } from '@common/components/forms';
import { MASKED_INPUT_TYPE } from '@common/constants/maskedInput';

const BirthdayInput = ({ setFieldValue, name, value, ...props }) => {
  const handleChange = val => {
    setFieldValue(name, val);
  };
  const { t } = useTranslation();

  const dateBlocksOptions = {
    Y: {
      from: 1900,
      mask: global.IMask.MaskedRange,
      maxLength: 4,
      placeholderChar: t('birthdayInput.placeholder.yearChar', 'J'),
      to: 2999,
    },
    d: {
      from: 1,
      mask: global.IMask.MaskedRange,
      maxLength: 2,
      placeholderChar: t('birthdayInput.placeholder.dayChar', 'T'),
      to: 31,
    },
    m: {
      from: 1,
      mask: global.IMask.MaskedRange,
      maxLength: 2,
      placeholderChar: t('birthdayInput.placeholder.monthChar', 'M'),
      to: 12,
    },
  };

  return (
    <TextInputMasked
      mask={Date}
      pattern="d{ }`m{ }`Y"
      format={date => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        const year = date.getFullYear();

        if (day < 10) day = `0${day}`;

        if (month < 10) month = `0${month}`;

        return [day, month, year].join(' ');
      }}
      parse={str => {
        const yearMonthDay = str.split(' ');

        return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
      }}
      unmask
      blocks={dateBlocksOptions}
      lazy={false}
      overwrite
      autofix
      onChange={handleChange}
      value={value}
      name={name}
      novalidate
      maskedInputType={MASKED_INPUT_TYPE.DATE}
      {...props}
    />
  );
};

BirthdayInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
BirthdayInput.defaultProps = {
  className: undefined,
};

export { BirthdayInput };
