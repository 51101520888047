import styled from 'styled-components';

import { getThemeTransition, getThemeColor } from '@utils/styled';

import { SIZES } from './constants';

export const Label = styled.label`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

function getTextPadding({ theme, size, isImage }) {
  switch (size) {
    case SIZES.LARGE:
      return [
        isImage
          ? 'padding-top: 0'
          : theme.getFluidSpacing('padding-top', 'scale', 4),
        isImage
          ? 'padding-bottom: 0'
          : theme.getFluidSpacing('padding-bottom', 'scale', 4),
        'padding-left: 2rem;',
        'padding-right: 2rem;',
      ];
    case SIZES.NORMAL:
      return [
        theme.getFluidSpacing('padding-top', 'equal', 6),
        theme.getFluidSpacing('padding-bottom', 'equal', 6),
        theme.getFluidSpacing('padding-left', 'equal', 4),
        theme.getFluidSpacing('padding-right', 'equal', 4),
      ];
    case SIZES.SMALL:
    default:
      return [
        'padding-top: 0;',
        'padding-bottom: 0;',
        theme.getFluidSpacing('padding-left', 'equal', 5),
        theme.getFluidSpacing('padding-right', 'equal', 5),
      ];
  }
}

function getFontWeight({ size }) {
  switch (size) {
    case SIZES.NORMAL:
      return 300;
    case SIZES.SMALL:
    default:
      return 500;
  }
}

function getColor({ disabled, checked, size }) {
  if (checked) {
    if (size === SIZES.LARGE) return getThemeColor('bluegrey.15');

    return getThemeColor('hue.blue');
  }

  if (disabled) {
    return getThemeColor('bluegrey.80');
  }

  switch (size) {
    case SIZES.SMALL:
      return getThemeColor('grey.0');
    default:
      return getThemeColor('bluegrey.15');
  }
}

function getBorderColor({ checked }) {
  if (checked) {
    return getThemeColor('hue.blue');
  }

  return getThemeColor('bluegrey.95');
}

export const TextWrapper = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => (size === SIZES.LARGE ? '100%' : 'auto')};
  height: 100%;
  border-radius: ${({ theme, size }) =>
    size === SIZES.LARGE ? '1rem' : `${theme.borderRadiuses.pill}rem`};
  background-color: ${getThemeColor('bluegrey.95')};
  font-weight: ${getFontWeight};
  ${({ theme, size }) =>
    size === SIZES.LARGE
      ? theme.getFluidFont('h5')
      : theme.getFluidFont('paragraph1')};
  ${getTextPadding};
  text-align: center;
  text-decoration: none;
  ${({ theme, isImage }) =>
    isImage && theme.getFluidSpacing('height', 'scale', 10)};

  &:hover:not(:disabled) {
    transition: ${getThemeTransition('all')};
  }

  &:focus:not(:disabled) {
    transition: ${getThemeTransition('all')};
  }
`;

export const Input = styled.input`
  position: absolute;
  bottom: 0;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;

  + ${TextWrapper} {
    border: 0.2rem solid ${getBorderColor};
    color: ${getColor};
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:active:not([disabled]) {
    outline: none;

    + ${TextWrapper} {
      border-color: ${getThemeColor('hue.blue')};
      background-color: ${getThemeColor('bluegrey.90')};
      color: ${({ size }) =>
        getThemeColor(size === SIZES.LARGE ? 'bluegrey.15' : 'hue.blue')};
    }
  }
`;

export const RadiosWrapper = styled.div`
  display: grid;
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'equal', 1)};
  grid-auto-columns: min-content;
  grid-auto-flow: column;
`;
