import styled, { css } from 'styled-components';

import {
  getThemeColor,
  getThemeColorWithError,
  getThemePath,
  getThemeTransition,
} from '@utils/styled';

import * as FormControl from '@common/components/forms/FormControl/FormControl.styled';

export const Label = styled(FormControl.Label)`
  flex: 1 1 auto;
  width: 0;
`;

export const LabelRow = styled(FormControl.LabelRow)``;

export const LabelText = styled(FormControl.LabelText)``;

export const OptionalText = styled(FormControl.OptionalText)``;

export const Input = styled.input`
  flex: 1 1 auto;
  width: 100%;
  height: 5.1rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${getThemeColorWithError('bluegrey.80', 'hue.red')};
  border-radius: ${getThemePath(['borderRadiuses', 'normal.1'])}rem;
  padding: ${({ $isPasswordField }) =>
    $isPasswordField ? '0 5.1rem 0 2rem ' : '0 2rem'};
  background-color: ${getThemeColorWithError('grey.100', 'hue.errorYellow')};
  ${({ theme }) => theme.getFluidFont('paragraph2')};
  color: ${getThemeColorWithError('bluegrey.15', 'hue.red')};
  line-height: 5.1rem;
  transition: ${getThemeTransition('all')};
  ${({ widthDefaultSpacing, theme }) =>
    widthDefaultSpacing
      ? theme.getFluidSpacing('margin-left', 'equal', 5)
      : null}

  ${/* sc-sel */ FormControl.Label} & {
    width: 0;
  }

  ${/* sc-sel */ Label} & {
    width: 100%;
  }

  &:disabled {
    border-color: ${getThemeColor('bluegrey.95')};
    background-color: ${getThemeColor('bluegrey.95')};
    cursor: not-allowed;
  }

  &:autofill {
    box-shadow: 0 0 0 max(1000vmax, 10000px) ${getThemeColor('hue.aliceBlue')}
      inset;
    border-color: ${getThemeColor('hue.malibu')} !important;
  }

  &:hover:not(:disabled) {
    border-color: ${getThemeColorWithError('bluegrey.60', 'hue.red')};
    transition: ${getThemeTransition('all')};
  }

  &:focus:not(:disabled) {
    outline: none;
    border-color: ${getThemeColorWithError('bluegrey.15', 'hue.red')};
    transition: ${getThemeTransition('all')};
  }

  &::placeholder {
    opacity: 1;
    color: ${getThemeColor('bluegrey.80')};
  }

  ${
    /* sc-block */ ({ type }) =>
      type === 'number' &&
      css`
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        & {
          -moz-appearance: textfield;
        }
      `
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const PasswordToggle = styled.button`
  position: absolute;
  top: 0;
  ${({ theme }) => theme.getFluidSpacing('right', 'equal', 3)}
  bottom: 0;
  display: flex;
  align-items: center;
  margin: auto;
  outline: none;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;

  > svg {
    transition: ${getThemeTransition('fill')};
    fill: ${({ isPasswordVisible }) =>
      isPasswordVisible
        ? getThemeColor('hue.blue')
        : getThemeColor('bluegrey.70')};
  }

  &:hover,
  &:focus {
    > svg {
      fill: ${({ isPasswordVisible }) =>
        isPasswordVisible
          ? getThemeColor('bluegrey.15')
          : getThemeColor('bluegrey.15')};
    }
  }
`;
