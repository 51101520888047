export const getFormattedMaskValue = (rawValue, maskValue) => {
  const reversed = rawValue.split('').reverse();

  return maskValue
    .split('')
    .reduce(
      (accum, char) => [...accum, char === ' ' ? char : reversed.pop()],
      [],
    )
    .join('')
    .trim();
};
