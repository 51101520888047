import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import getConfig from 'next/config';

import { selectAddressApiToken } from '@selectors/addressApi';

import { extractPhoneParts } from '@utils/phone';

import {
  COUNTRY_PHONE_PREFIX_SWITZERLAND,
  COUNTRY_SWITZERLAND,
} from '@common/constants/countries';
import { DAY_MONTH_YEAR, YEAR_MONTH_DAY } from '@common/constants/dates';
import { GENDER_FEMALE } from '@common/constants/genders';
import { LANGUAGE_DE } from '@common/constants/languages';

const { ADDRESS_API_URL } = getConfig().publicRuntimeConfig;

export const checkIsStreetNumber = async (formValues, token, formNamesMap) => {
  const response = await axios.get(
    `${ADDRESS_API_URL}/buildingverification4?${new URLSearchParams({
      zipcode: formValues[formNamesMap.zipCode] || '',
      streetname: formValues[formNamesMap.street] || '',
    })}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return (
    +response.data?.QueryBuildingVerification4Result?.BuildingVerificationData
      ?.HouseKey > 0
  );
};

export function useProfileDetailsInitialValues(details) {
  const addressApiToken = useSelector(selectAddressApiToken);
  const [isStreetNumberAvailable, setIsStreetNumberAvailable] = useState(true);

  useEffect(() => {
    checkIsStreetNumber(details?.personal?.address, addressApiToken, {
      zipCode: 'zipCode',
      street: 'street',
    }).then(isStreetNumber => {
      setIsStreetNumberAvailable(isStreetNumber);
    });
  }, [details, addressApiToken]);

  const birthDate = details?.personal?.dateOfBirth
    ? new Date(details.personal.dateOfBirth)
    : false;
  const mobilePhone =
    details?.phoneData?.phone || COUNTRY_PHONE_PREFIX_SWITZERLAND;
  const { prefix: phonePrefix, phone: phoneNumber } =
    extractPhoneParts(mobilePhone);

  return {
    birthDate,
    birthDateString: birthDate ? format(birthDate, DAY_MONTH_YEAR) : '',
    birthDay: birthDate ? birthDate?.getDate() || '' : '',
    birthMonth: birthDate ? birthDate?.getMonth() || '0' : '0',
    birthYear: birthDate ? birthDate?.getFullYear() || '' : '',
    country: details?.personal?.address?.country || COUNTRY_SWITZERLAND,
    firstName: details?.personal?.firstname,
    gender: details?.personal?.gender?.toString() || GENDER_FEMALE,
    language: details?.personal?.language || LANGUAGE_DE,
    lastName: details?.personal?.lastname,
    mobilePhone,
    phoneNumber,
    phonePrefix,
    additionalAddress: details?.personal?.address?.additionalAddress || '',
    streetName: details?.personal?.address?.street,
    streetNr: details?.personal?.address?.houseNr,
    zipCity: details?.personal?.address?.city,
    zipCode: details?.personal?.address?.zipCode,
    noAdditionalStreetNumber: isStreetNumberAvailable,
  };
}

export function formFieldsToProfileDetails(values) {
  const phone = values.phoneNumber ? values.mobilePhone : '';

  return {
    personal: {
      address: {
        additionalAddress: values.additionalAddress,
        city: values.zipCity,
        country: values.country,
        houseNr: values.streetNr,
        street: values.streetName,
        zipCode: values.zipCode,
      },
      dateOfBirth: format(values.birthDate, YEAR_MONTH_DAY),
      firstname: values.firstName,
      gender: Number(values.gender),
      language: values.language,
      lastname: values.lastName,
    },
    phone_data: {
      phone,
    },
  };
}
