import styled, { css } from 'styled-components';

import { getThemeColor, getThemeColorWithError } from '@utils/styled';

import { TextInputMasked } from '@common/components/forms';

const borderIndicator = css`
  &::after {
    content: '';
    position: absolute;
    top: 0.8rem;
    right: 0;
    bottom: 0.8rem;
    display: block;
    width: 1px;
    background-color: ${getThemeColor('bluegrey.90')};
  }
`;

export const Container = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

export const FixedPrefix = styled.span`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${getThemeColorWithError('bluegrey.15', 'hue.red')};
  font-size: 2rem;

  > svg {
    flex-shrink: 0;
    margin-right: 5px;
  }

  ${borderIndicator}
`;

export const PrefixOption = styled.div`
  display: flex;
  align-items: center;

  > svg {
    flex-shrink: 0;
    margin-right: 5px;
  }
`;

export const NumberTextInput = styled(TextInputMasked)`
  > input {
    padding-left: ${({ fixedPrefix }) => (fixedPrefix ? '11.4rem' : '13rem')};
  }
`;

export const PrefixWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ fixedPrefix }) => (fixedPrefix ? '10.2rem' : '11.8rem')};
  height: 100%;
`;
